<template>
  <div>
    <idea-score-card
      class="mb-3"
      :img="require(`@/assets/img/icons/story-mode/concept-icon.svg`)"
      :title="$t('steps.concept.title')"
      :description="$t('steps.concept.description')"
    >
      <template #right>
        <idea-score-card-pill section="concept" />
      </template>
    </idea-score-card>

    <idea-score-card
      class="mb-3"
      :img="require(`@/assets/img/icons/story-mode/research-icon.svg`)"
      :title="$t('steps.research.title')"
      :description="$t('steps.research.description')"
    >
      <template #right>
        <idea-score-card-pill section="research" />
      </template>
    </idea-score-card>

    <idea-score-card
      class="mb-3"
      :img="require(`@/assets/img/icons/story-mode/set-up-icon.svg`)"
      :title="$t('steps.setUp.title')"
      :description="$t('steps.setUp.description')"
    >
      <template #right>
        <idea-score-card-pill section="setUp" />
      </template>
    </idea-score-card>

    <idea-score-card
      :img="require(`@/assets/img/icons/story-mode/projections-icon.svg`)"
      :title="$t('steps.projections.title')"
      :description="$t('steps.projections.description')"
    >
      <template #right>
        <idea-score-card-pill section="projection" />
      </template>
    </idea-score-card>
  </div>
</template>

<script>
import IdeaScoreCard from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreCard'
import IdeaScoreCardPill from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreCardPill'

export default {
  name: 'IdeaScoreCards',

  components: {
    IdeaScoreCard,
    IdeaScoreCardPill
  }
}
</script>
