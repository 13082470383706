import { render, staticRenderFns } from "./IdeaScoreCard.vue?vue&type=template&id=11ebce7b&scoped=true"
import script from "./IdeaScoreCard.vue?vue&type=script&lang=js"
export * from "./IdeaScoreCard.vue?vue&type=script&lang=js"
import style0 from "./IdeaScoreCard.vue?vue&type=style&index=0&id=11ebce7b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ebce7b",
  null
  
)

export default component.exports