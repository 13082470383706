<template>
  <ib-card class="idea-score-card p-3" :animation="false">
    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
      <div class="d-flex mb-3 mb-md-0">
        <img :src="img" width="80" class="mr-3">
        <div class="mr-2 flex-grow-1">
          <p class="m-0 mb-1 h5">
            {{ title }}
          </p>
          <p class="m-0 o-50">
            {{ description }}
          </p>
        </div>
      </div>

      <div class="right-slot">
        <slot name="right" />
      </div>
    </div>
  </ib-card>
</template>

<script>
export default {
  name: 'IdeaScoreCard',

  props: {
    img: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.idea-score-card {
  .right-slot {
    width: 100%;

    @include media-breakpoint-up($md) {
      width: unset;
    }
  }
}
</style>
