<template>
  <ib-dialog
    :visible="visible"
    :closable="false"
    empty-header
    top="8vh"
    width="850px"
    @close="skip"
  >
    <!-- Quick Tour -->
    <idea-score-quick-tour video-height="250" />
    <!-- Quick Tour -->

    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('upgradeToContinue')"
        :cancel-btn-text="$t('back')"
        :visible-submit-btn="subscriptionIsDreamer || subscriptionIsFreeTrial"
        @submit="$router.push({ name: 'payment' })"
        @cancel="skip"
      />
    </template>
  </ib-dialog>
</template>

<script>
import IdeaScoreQuickTour from '@/views/Home/Components/QuickTours/IdeaScoreQuickTour.vue'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

export default {
  name: 'DialogIdeaScoreQuickTour',

  components: { IdeaScoreQuickTour },

  mixins: [MixinUserSubscription],

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    skip () {
      this.$emit('skip')
      if (this.subscriptionIsFreeTrial || this.subscriptionIsDreamer) {
        this.$router.go(-1)
      }
    }
  }
}
</script>
