<template>
  <div
    class="idea-score-card-pill p-2 text-center "
    :class="isFullyCompleted ? 'fully-completed' : 'partially-completed'"
  >
    <span class="text-lowercase">
      {{ completedStepsCount }}/{{ totalStepsCount }} {{ $t('completed') }}
    </span>
  </div>
</template>

<script>
import businessGuideNavigationsSteps from '@/data/businessGuideNavigationsSteps'
import { mapGetters } from 'vuex'

export default {
  name: 'IdeaScoreCardPill',

  props: {
    section: {
      type: String,
      validator: value => {
        return Object.keys(businessGuideNavigationsSteps).includes(value)
      },
      required: true
    }
  },

  computed: {
    ...mapGetters('idea', ['getCompletedSteps']),

    isFullyCompleted () {
      return this.totalStepsCount === this.completedStepsCount
    },

    totalStepsCount () {
      return businessGuideNavigationsSteps[this.section].children.filter((step) => {
        return step.route !== null
      }).length
    },

    completedStepsCount () {
      let count = 0
      const completedNames = businessGuideNavigationsSteps[this.section].children
        .map(section => section.completedStepName)
        .filter(completedStepName => completedStepName !== null)

      for (let i = 0; i < completedNames.length; i++) {
        if (this.getCompletedSteps[completedNames[i]] === true) {
          count++
        }
      }

      return count
    }
  }
}
</script>

<style scoped lang="scss">
.idea-score-card-pill {
  border-radius: 8px;

  &.fully-completed {
    color: $color-white;
    background-color: $color-green;
  }

  &.partially-completed {
    color: $color-royal-blue;
    background-color: #e1e8f7;
  }

  span {
    font-family: $outfit-semi-bold;
    font-size: 15px;
    white-space: nowrap;
  }
}
</style>
