<template>
  <video-quick-tour
    :height="videoHeight"
    video-name="idea_validationVideo"
  >
    <h4 class="my-3 my-xl-0 font-outfit-medium">
      {{ $t('sidebar.validation') }}
    </h4>
    <div class="quick-tour-text" v-html="$t('ideaScoreQuickTourText')" />
  </video-quick-tour>
</template>

<script>
import VideoQuickTour from '@/components/_v2/VideoQuickTour'

export default {
  name: 'IdeaScoreQuickTour',

  components: {
    VideoQuickTour
  },

  props: {
    videoHeight: {
      type: String,
      default: '320'
    }
  }
}
</script>
