<template>
  <ib-dialog
    :visible="visible"
    fullscreen
    empty-header
    @close="$emit('close')"
  >
    <ib-dialog-container max-width="900px">
      <div class="init-dialog-head text-center">
        <h1 class="mb-0">
          {{ $t('dialogs.validation.initDialog.letsValidateYourBusinessIdea') }}
        </h1>
        <p class="m-0">
          {{ $t('dialogs.validation.initDialog.completeTheTestToDetermine') }}
        </p>
      </div>

      <ib-dialog-container class="background-wrapper" max-width="460px">
        <ib-card
          class="init-dialog-card mb-3 mt-5"
          :animation="false"
        >
          <div class="text-center mb-5">
            <img
              class="d-block m-auto"
              src="@/assets/img/illustrations/idea_score.svg"
              alt=""
            >
            <p class="m-0 h4">
              {{ $t('dialogs.validation.initDialog.ideaScore') }}
            </p>
          </div>

          <div>
            <idea-score-checkmark-text
              class="mb-3"
              :text="$t('dialogs.validation.initDialog.theIdeaScoreIsOurValidationTool')"
            />

            <idea-score-checkmark-text
              class="mb-3"
              :text="$t('dialogs.validation.initDialog.rememberTheAccuracyAndHonestyOfYourResponses')"
            />

            <idea-score-checkmark-text>
              <template #text>
                <p class="m-0" v-html="$t('dialogs.validation.initDialog.ifYouEncounterAnyUncertaintiesAlongTheWay')" />
              </template>
            </idea-score-checkmark-text>
          </div>
        </ib-card>

        <ib-button
          class="p-3 w-100 text-uppercase"
          @click="$router.push({name: 'validation-idea-score-test'})"
        >
          {{ $t('pages.businessGuide.validation.ideaScore.startTheTest') }}
        </ib-button>
      </ib-dialog-container>
    </ib-dialog-container>
  </ib-dialog>
</template>

<script>
import IdeaScoreCheckmarkText from '@/views/Home/StoryMode/Validation/IdeaScore/Components/IdeaScoreCheckmarkText'

export default {
  name: 'DialogValidationInit',

  components: { IdeaScoreCheckmarkText },

  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.init-dialog-head {
  margin-top: 2vh;

  @include media-breakpoint-up($md) {
    margin-top: 10vh;
  }

  p {
    font-size: 16px;
  }
}

.init-dialog-card {
  padding: 45px 10px;

  @include media-breakpoint-up($sm) {
    padding: 28px 31px 45px 31px;
  }
}
</style>
